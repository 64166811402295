<template>
    <div>
        <Header></Header>
        <div
            class="main-container"
            id="container"
            :class="[!$store.state.is_show_sidebar ? 'sidebar-closed sbar-open' : '', $store.state.menu_style === 'collapsible-vertical' ? 'collapsible-vertical-mobile' : '']"
        >
            <Sidebar></Sidebar>
            <div id="content" class="main-content">
                <router-view>
                    <div id="load_screen">
                        <div class="loader">
                            <div class="loader-content">
                                <div class="spinner-grow text-info align-self-center loader-lg">Loading...</div>
                            </div>
                        </div>
                    </div>
                </router-view>
            </div>
        </div>
    </div>
</template>
<script>
    import Header from '@/components/layout/header.vue';
    import Sidebar from '@/components/layout/sidebar.vue';
    import axios from 'axios';
    import * as clover from 'remote-pay-cloud';
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';

    export default {
        components: {
            Header,
            Sidebar,
        },
        data() {
            return {
                baseurl: this.$appSetting.hostname,
                params: { id: null, pos_name: '', device_uri: '', serial_no: '', app_id: '', token: '', status: '', last_paired: '' },
            };
        },
        beforeCreate() {
            if (!this.$store.state.center) {
                axios
                    .get('getCenterDetails/' + window.location.host)
                    .then((res) => {
                        if (res.data.error) {
                            console.log(res);
                        } else {
                            this.$store.dispatch('center', res.data.data);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            if (!this.$store.state.user) {
                if (localStorage.getItem('token')) {
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
                    axios
                        .get('getProfile')
                        .then((res) => {
                            if (res.data.error) {
                                //this.$swal('Login Failed', res.data.msg, 'error');
                                this.$router.push('/staff/login').catch(() => {
                                    this.$router.push('/staff/login');
                                });
                            } else {
                                this.$store.dispatch('user', res.data.data);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            this.$router.push('/staff/login').catch();
                        });
                } else {
                    // this.$swal('Login Required', 'You need to login', 'error');
                    this.$router.push('/staff/login').catch(() => {
                        this.$router.push('/staff/login');
                    });
                }
            }
        },
        mounted() {
            this.check_clover_device();
        },
        methods: {
            check_clover_device() {
                axios
                    .get('getCardMachine')
                    .then((res) => {
                        if (res.data.error) {
                            console.log(res);
                        } else {
                            this.params = JSON.parse(JSON.stringify(res.data.data));
                            if (this.params.status == 'active') {
                                this.pair_device();
                            } else {
                                this.$store.state.cloverConnector.dispose();
                                return null;
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            save_record() {
                if (!this.params.pos_name) {
                    this.$bvToast.toast('Pos name is required.', { headerClass: 'd-none', bodyClass: 'toast-danger text-center', toaster: 'b-toaster-top-center', autoHideDelay: 1000 });
                    return true;
                }
                if (!this.params.device_uri) {
                    this.$bvToast.toast('Device uri is required.', { headerClass: 'd-none', bodyClass: 'toast-danger text-center', toaster: 'b-toaster-top-center', autoHideDelay: 1000 });
                    return true;
                }
                if (!this.params.serial_no) {
                    this.$bvToast.toast('Device serial no is required.', { headerClass: 'd-none', bodyClass: 'toast-danger text-center', toaster: 'b-toaster-top-center', autoHideDelay: 1000 });
                    return true;
                }
                if (!this.params.status) {
                    this.$bvToast.toast('Status is required.', { headerClass: 'd-none', bodyClass: 'toast-danger text-center', toaster: 'b-toaster-top-center', autoHideDelay: 1000 });
                    return true;
                }

                axios
                    .post('updateCardMachine', this.params)
                    .then((res) => {
                        if (res.data.error) this.$bvToast.toast(res.data.msg, { headerClass: 'd-none', bodyClass: 'toast-danger text-center', toaster: 'b-toaster-top-center', autoHideDelay: 2000 });
                        else {
                            //this.$bvToast.toast('Clover Device Settings updated successfully.', { headerClass: 'd-none', bodyClass: 'toast-success text-center', toaster: 'b-toaster-top-center', autoHideDelay: 2000 });
                        }
                    })
                    .catch();
            },
            onPairingCode(code) {
                console.log('pairing code is ' + code);
                this.$swal('Clover Device Pairing', 'Clover device pairing code is ' + code + ' Enter this code in clover device', 'error');
            },
            onPairingSuccess(token) {
                console.log('pairing success token is ' + token);
                this.params.token = token;
                //this.$bvToast.toast('Clover Device Paired.', { headerClass: 'd-none', bodyClass: 'toast-success text-center', toaster: 'b-toaster-top-center', autoHideDelay: 2000 });
                this.save_record();
            },

            pair_device() {
                clover.remotepay.ICloverConnectorListener.prototype.vm = this;
                const networkConfigurationBuilder = new clover.WebSocketPairedCloverDeviceConfigurationBuilder(
                    this.params.app_id,
                    this.params.device_uri,
                    this.params.pos_name,
                    this.params.serial_no,
                    this.params.token,
                    this.onPairingCode,
                    this.onPairingSuccess
                );
                const pairedConfiguration = networkConfigurationBuilder.build();

                // Create a Clover Connector
                var builderConfiguration = {};
                builderConfiguration[clover.CloverConnectorFactoryBuilder.FACTORY_VERSION] = clover.CloverConnectorFactoryBuilder.VERSION_12;
                var cloverConnectorFactory = clover.CloverConnectorFactoryBuilder.createICloverConnectorFactory(builderConfiguration);
                if (this.$store.state.cloverConnector) this.$store.state.cloverConnector.dispose();
                this.$store.state.cloverConnector = cloverConnectorFactory.createICloverConnector(pairedConfiguration);
                console.log('cloverConnector', this.$store.state.cloverConnector);

                // Add a listener to the Clover Connector
                var defaultCloverConnectorListener = Object.assign({}, clover.remotepay.ICloverConnectorListener.prototype, {
                    onDeviceReady: function (merchantInfo) {
                        this.vm.$store.state.CloverRetry = 0;
                        this.vm.$bvToast.toast('Clover Device Paired.', { headerClass: 'd-none', bodyClass: 'toast-success text-center', toaster: 'b-toaster-top-center', autoHideDelay: 2000 });
                        // updateStatus("Pairing successfully completed, your Clover device is ready to process requests.");
                        console.log({ message: 'Device Ready to process requests!', merchantInfo: merchantInfo });
                        // const Sale_Request = JSON.parse(localStorage.getItem('pendingSaleRequest'));
                        //                         if (Sale_Request != null) {
                        //                             const retrieveDeviceStatusRequest = new clover.remotepay.RetrieveDeviceStatusRequest();
                        //                             retrieveDeviceStatusRequest.setSendLastMessage(true);
                        //                             this.vm.$store.state.cloverConnector.retrieveDeviceStatus(retrieveDeviceStatusRequest);
                        //                             const retrievePaymentRequest = new clover.remotepay.RetrievePaymentRequest();
                        //                             retrievePaymentRequest.setExternalPaymentId(this.vm.$store.state.pendingSaleRequest.getExternalId());
                        //                             this.vm.$store.state.cloverConnector.retrievePayment(retrievePaymentRequest);
                        //                         }
                    },
                    onDeviceDisconnected: function () {
                        console.log({ message: 'Disconnected' });
                        this.vm.$store.state.CloverRetry++;
                        if (this.vm.$store.state.CloverRetry > 5) {
                            this.vm.$store.state.cloverConnector.dispose();
                        }
                        //if (this.vm.$store.state.cloverConnector) this.vm.$store.state.cloverConnector.dispose();
                    },
                    onRefundPaymentResponse: function (response) {
                        if (response.success) {
                            this.vm.cloverStatus = response.message;
                        } else {
                            this.vm.cloverStatus = response.message;
                        }
                        this.vm.$store.state.refundpaymentresponse = response;
                    },
                    onDeviceConnected: function () {
                        console.log({ message: 'Connected, but not available to process requests' });
                    },
                    onDeviceError: function (cloverDeviceErrorEvent) {
                        console.log({ message: `An error has occurred: ${cloverDeviceErrorEvent.getMessage()}` });
                        //if (this.vm.$store.state.cloverConnector) this.vm.$store.state.cloverConnector.dispose();
                    },
                    onResetDeviceResponse() {
                        if (this.vm.pendingSaleRequest) {
                            // Verify the payment status, the reset will generally void payments, but not in all cases.
                            const retrievePaymentRequest = new clover.remotepay.RetrievePaymentRequest();
                            retrievePaymentRequest.setExternalPaymentId(this.vm.pendingSaleRequest.getExternalId());
                            this.vm.$store.state.cloverConnector.retrievePayment(retrievePaymentRequest);
                        }
                    },
                    onDeviceStatusResponse: function (status) {
                        console.log('status', status);
                    },
                    onConfirmPaymentRequest: function (request) {
                        console.log({ message: 'Automatically accepting payment', request: request });
                        this.vm.$store.state.cloverConnector.acceptPayment(request.getPayment());
                        // to reject a payment, pass the payment and the challenge that was the basis for the rejection
                        // getCloverConnector().rejectPayment(request.getPayment(), request.getChallenges()[REJECTED_CHALLENGE_INDEX]);
                    },
                    onVerifySignatureRequest: function (request) {
                        console.log({ message: 'Automatically accepting signature', request: request });
                        this.vm.$store.state.cloverConnector.acceptSignature(request);
                        // to reject a signature, pass the request to verify
                        // getCloverConnector().rejectSignature(request);
                    },
                    onSaleResponse: function (response) {
                        var requestExternalId = this.vm.$store.state.cloverExternalId;
                        this.vm.$store.state.cloverPaymentDone = true;
                        this.vm.$store.state.saleresponse = response;
                        localStorage.setItem('Sale_Response', response);

                        console.log('requestExternalId', requestExternalId);
                        let status = 'FAILED';

                        if (response.getPayment()) {
                            const payment = response.getPayment();
                            requestExternalId = payment.getExternalPaymentId();

                            if (response.getSuccess()) {
                                if (response.isAuth && payment) {
                                    const voidPaymentRequest = new clover.remotepay.VoidPaymentRequest();
                                    voidPaymentRequest.setPaymentId(payment.getId());
                                    voidPaymentRequest.setVoidReason(clover.order.VoidReason.USER_CANCEL);
                                    status = 'FAILED';
                                } else {
                                    status = 'COMPLETED';
                                }
                            }
                        } else {
                            if (response.getStatus() === 'SUCCESS') {
                                status = 'COMPLETED';
                            }
                            if (response.getStatus() === 'FAIL') {
                                status = 'FAILED';
                            }
                            if (response.getStatus() === 'UNSUPPORTED') {
                                status = 'UNSUPPORTED';
                            }
                            if (response.getStatus() === 'CANCEL') {
                                status = 'CANCELLED';
                            }
                            if (response.getStatus() === 'ERROR') {
                                status = 'ERROR';
                            }
                        }
                        this.vm.$store.state.clover_payment_status = '';
                        this.vm.$store.state.clover_payment_status = status;
                    },
                    onRetrievePaymentResponse: function (retrievePaymentResponse) {
                        console.log({ message: 'onRetrievePaymentResponse', response: retrievePaymentResponse });
                        this.vm.$store.state.saleresponse = retrievePaymentResponse;
                        // const requestExternalId = retrievePaymentResponse.getExternalPaymentId();
                        let status = 'FAILED';
                        if (retrievePaymentResponse.getQueryStatus() === clover.remotepay.QueryStatus.FOUND) {
                            this.vm.$store.state.pendingSaleRequest = null;
                            //   this.Sale_Request = JSON.parse(localStorage.getItem('pendingSaleRequest'));
                            status = 'COMPLETED';
                        } else if (retrievePaymentResponse.getQueryStatus() === clover.remotepay.QueryStatus.IN_PROGRESS) {
                            status = 'IN_PROCESS';
                        } else this.vm.$store.state.pendingSaleRequest = null;
                        this.vm.$store.state.clover_payment_status = '';

                        this.vm.$store.state.clover_payment_status = status;
                        this.vm.$store.state.cloverConnector.device.doShowWelcomeScreen();
                    },
                    onDeviceActivityStart: function (status) {
                        this.vm.$store.state.cloverPaymentStatus = status.message;
                        this.vm.$store.state.cloverPaymentButton = status.inputOptions;
                        console.log('Activity start status::::', status);
                        // if (status.eventState == 'RECEIPT_OPTIONS') {
                        //     const inputOption = new clover.remotepay.InputOption();
                        //     inputOption.setKeyPress(clover.remotepay.KeyPress.BUTTON_1);
                        //     this.vm.$store.state.cloverConnector.invokeInputOption(inputOption);
                        //     this.vm.$store.state.cloverConnector.device.doShowWelcomeScreen();
                        // }
                    },
                    onDeviceActivityEnd: function (status) {
                        this.vm.$store.state.cloverPaymentStatus = '';
                        console.log('Activity end status>>>>>>>', status);
                    },
                });
                // this.$store.state.defaultCloverConnectorListener = defaultCloverConnectorListener;
                localStorage.setItem('defaultCloverConnectorListener', defaultCloverConnectorListener);
                this.$store.state.cloverConnector.addCloverConnectorListener(defaultCloverConnectorListener);
                this.$store.state.cloverConnector.addCloverConnectorListener(defaultCloverConnectorListener);
                // Initialize the connection
                this.$store.state.cloverConnector.initializeConnection();

                // Display a message on the Clover device
                this.$store.state.cloverConnector.showMessage('Welcome to Clover Connector.');
                //this.cloverConnector.dispose();
            },
        },
    };
</script>
